import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import CheckInService from '../../../api/CheckInService.js'

export default{
	name:'MechanismDetail',
	data(){
		return{
			MechanismInfo:'',
			RelationBookList:[],//相关图书
			RelationImageList:[],//相关图片
			RelationEntryList:[],//相关论文
			RelationMechanismList:[],//相关机构
		}
	},
	methods:{
		GetMechanismInfo(){ //获取机构详情
			var that = this
			CheckInService.GetMechanismInfo(that.$route.query.id,that.$UserID).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.MechanismInfo = res.Result
				}
			})
		},
		GetExpertInfo(){ //获取专家详情
			var that = this
			CheckInService.GetExpertInfo(that.$route.query.id,that.$UserID).then(res=>{
				if(res.Tag == 1){
					that.MechanismInfo = res.Result
				}
			})
		},
		GetRelationBookList(){ //获取相关图书
			var that = this
			CheckInService.GetLibraryRelationList(8,that.$route.query.id,0).then(res=>{
				if(res.Tag == 1){
					that.RelationBookList = res.Result
				}
			})
		},
		GetRelationImageList(){ //获取相关图片
			var that = this
			CheckInService.GetLibraryRelationList(5,that.$route.query.id,1).then(res=>{
				if(res.Tag == 1){
					that.RelationImageList = res.Result
				}
			})
		},
		GetRelationEntryList(){ //获取相关论文
			var that = this
			CheckInService.GetLibraryRelationList(9,that.$route.query.id,5).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.RelationEntryList = res.Result
				}
			})
		},
		GetRelationMechanismList(){ //获取相关机构
			var that = this
			CheckInService.GetLibraryRelationList(3,that.$route.query.id,6).then(res=>{
				if(res.Tag == 1){
					that.RelationMechanismList = res.Result
				}
			})
		},
		toBookDetail(id){ //图书详情
			window.open(this.$WebUrl + 'BookDetail?id=' + id)
		},
		toImageDetail(id){ //图片详情
			window.open(this.$WebUrl + 'ImageDetail?id=' + id)
		},
		toEntryDetail(id){ //条目详情
			window.open(this.$WebUrl + 'EntryDetail?id=' + id)
		},
		toElementDetail(id){//词条详情
			window.open(this.$WebUrl + 'ElementDetail?id=' + id)
		},
		toLogin(){
			this.showLogin = false
			this.showLogin = true
		}
		
	},
	mounted(){
		if(this.$route.query.type == 2 ){ //专家
			this.GetExpertInfo()
			this.GetRelationMechanismList()
		}else{ //机构
			this.GetMechanismInfo()
		}
		this.GetRelationBookList()
		this.GetRelationImageList()
		this.GetRelationEntryList()
	},
	watch: {
		$route() {
			location.reload()
		}
	},
	components:{
		'Header': Header,
		'Footer':Footer
	}
}